@import url("https://use.typekit.net/plp2imt.css");
@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: "niveau-grotesk", sans-serif;
  font-weight: 400;
  font-style: normal;
}
* {
  font-family: "niveau-grotesk", sans-serif;
}
